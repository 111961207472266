import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  :root {
    --site-max-width: 1200px;
  }
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: ${props => props.theme.colors.fullLight};
    color: ${props => props.theme.colors.fullDark};
    font-family: ${props => props.theme.fonts.paragraphs};
    -webkit-font-smoothing: antialiased;
  }
  section {
    max-width: 960px;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 2.8rem;
    color: ${props => props.theme.colors.fullDark};
    font-family: ${props => props.theme.fonts.headings};
    transition: all 0.5s ease;
    @media screen and (min-width: 769px) {
      font-size: 5.5rem;
      line-height: 1.5;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.paragraphs};
    font-size: 1rem;
    line-height: 1.8rem;
    @media screen and (min-width: 769px) {
      font-size: 1.2rem;
      line-height: 2.2rem;
    }
  }

  h2 {
    color: ${props => props.theme.colors.mainBlue};
    font-size: 1.8rem;
    text-transform: uppercase;
    @media screen and (min-width: 769px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    @media screen and (min-width: 769px) {
      font-size: 1.2rem;
    
    }
  }

  a {
    text-decoration: none;
  }
  button {
    cursor: pointer;
  }
`
