import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaBars } from "react-icons/fa"
import scrollTo from "gatsby-plugin-smoothscroll"

//hooks
import { useHeaderDataQuery } from "hooks/useHeaderDataQuery"
//styles
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavButton,
  NavBtnLink,
} from "./Navbar.styles"

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const { logoImg, button, nav } = useHeaderDataQuery()

  const handlescroll = () =>
    window.scrollY >= 100 ? setScrollNav(true) : setScrollNav(false)
  const toogleHome = () => {
    scrollTo("#home")
  }
  useEffect(() => {
    window.addEventListener("scroll", handlescroll)
  }, [scrollNav])

  return (
    <>
      <Nav scroll={scrollNav} onClick={toogleHome}>
        <NavbarContainer>
          <NavLogo to="/">
            <GatsbyImage
              image={logoImg.childImageSharp.gatsbyImageData}
              alt="Logo radiologia odonto digital"
            />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {nav.map(item => (
              <NavItem key={item.id}>
                <NavLinks
                  to={item.link}
                  smooth="true"
                  duration={500}
                  spy="true"
                  offset={-100}
                >
                  {item.name}
                </NavLinks>
              </NavItem>
            ))}
            <NavButton>
              <NavBtnLink to={button.link}>{button.text}</NavBtnLink>
            </NavButton>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
