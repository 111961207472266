import React from "react"
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarWrapper,
  SidebarMenu,
  SideButtonWrapper,
  SidebarRoute,
} from "./styles"

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Home
          </SidebarLink>
          <SidebarLink to="/sobre-nos" onClick={toggle}>
            Sobre nós
          </SidebarLink>
          <SidebarLink to="/servicos" onClick={toggle}>
            Serviços
          </SidebarLink>
          <SidebarLink to="/onde-estamos" onClick={toggle}>
            Onde Estamos
          </SidebarLink>
        </SidebarMenu>
        <SideButtonWrapper>
          <SidebarRoute to="/agendamento">Agendamento</SidebarRoute>
        </SideButtonWrapper>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
