exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agendamento-index-jsx": () => import("./../../../src/pages/agendamento/index.jsx" /* webpackChunkName: "component---src-pages-agendamento-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-onde-estamos-index-jsx": () => import("./../../../src/pages/onde-estamos/index.jsx" /* webpackChunkName: "component---src-pages-onde-estamos-index-jsx" */),
  "component---src-pages-servicos-index-jsx": () => import("./../../../src/pages/servicos/index.jsx" /* webpackChunkName: "component---src-pages-servicos-index-jsx" */),
  "component---src-pages-sobre-nos-index-jsx": () => import("./../../../src/pages/sobre-nos/index.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-index-jsx" */),
  "component---src-pages-sobre-nos-video-jsx": () => import("./../../../src/pages/sobre-nos/Video.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-video-jsx" */)
}

