import { useStaticQuery, graphql } from "gatsby"

export const useHeaderDataQuery = () => {
  const data = useStaticQuery(graphql`
    query HeaderDataQuery {
      mdx(frontmatter: { type: { eq: "header" } }) {
        frontmatter {
          logoImg {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
            }
          }
          button {
            link
            text
          }
          nav {
            link
            name
            id
          }
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
