import React, { useState } from "react"

//comps
import FooterSection from "components/FooterSections"
import Navbar from "components/Navbar"
import Sidebar from "components/Sidebar"
//styles


const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <main>{children}</main>
      <FooterSection />
    </>
  )
}

export default Layout
