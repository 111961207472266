import React from "react"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"

import { GlobalStyles } from "./globalStyles"

const defaultTheme = {
  fonts: {
    paragraphs: "Source Sans Pro, sans-serif",
    headings: "Roboto Condensed, sans-serif",
  },
  colors: {
    fullDark: "#323232",
    fullLight: "#FAFAFA",
    mainBlue: "#056399",
    mainRed: "#AC3C3D",
    mainGrey: "#ECF0F1",
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyles />
    <Normalize />
    {children}
  </ThemeProvider>
)

export default Theme
