import styled from "styled-components"

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  background-color: #202020;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 56px;
  justify-content: center;

  @media screen and (max-width: 375px) {
    max-width: 340px;
  }
`

export const ContentWrapper = styled.div`
  max-width: 600px;
  text-align: center;
  p {
    color: #fafafa;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.6em;
  }
`
export const BottomFooter = styled.div`
  display: flex;
  justify-content: center;
  background-color: #000;
  width: 100%;
`

export const WrapperWebsiteRights = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  @media only screen and (max-width: 375px) {
    width: 100%;
  }
`

export const WebsiteRights = styled.small`
  color: #fff;
  padding: 16px;
  a {
    color: #add8e6;
  }
`
