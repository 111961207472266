import React from "react"

import {
  BottomFooter,
  ContentWrapper,
  FooterContainer,
  FooterWrapper,
  WebsiteRights,
  WrapperWebsiteRights,
} from "./Footer.styles"

const FooterSection = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <ContentWrapper>
            <p>
              Avenida Santa Catarina, 1460 – Barra Velha - Centro | Fone: (47)
              3307-2909 | WhatsApp: (47) 99772-9131 Email:
              radiologia.odontodigital@gmail.com
            </p>
            <p>
              Todos Direitos Reservados à © {new Date().getFullYear()}{" "}
              Radiologia Odonto Digital
            </p>
          </ContentWrapper>
        </FooterWrapper>
      </FooterContainer>
      <BottomFooter>
        <WrapperWebsiteRights>
          <WebsiteRights>
            Desenvolvido por <a href="https://byteshouse.com">Bytes House</a>
          </WebsiteRights>
        </WrapperWebsiteRights>
      </BottomFooter>
    </>
  )
}

export default FooterSection
